import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zsevic.github.io/zsevic.github.io/node_modules/gatsby-theme-chronoblog/src/components/page/index.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SEO = makeShortcode("SEO");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEO title="NestJS Starter" mdxType="SEO" />
    <h1 {...{
      "id": "nestjs-starter-a-solid-start-for-your-backend"
    }}>{`NestJS Starter: A Solid Start for Your Backend`}</h1>
    <p>{`NestJS boilerplate offers a reliable foundation for building server-side applications effectively.`}</p>
    <h2 {...{
      "id": "key-features"
    }}>{`Key Features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`User Endpoints: Fetch, list, and create users effortlessly.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Postgres Database: Integrated with migrations and seeders.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`CI Pipeline: Automated workflows with GitHub Actions.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Testing: Comprehensive unit, integration, and e2e tests.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`API Documentation: Detailed docs for easy integration.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Docker Ready: Hassle-free deployment.`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "tech-stack"
    }}>{`Tech Stack`}</h2>
    <ul>
      <li parentName="ul">{`Node.js, TypeScript, NestJS, TypeORM`}</li>
    </ul>
    <h2 {...{
      "id": "getting-started"
    }}>{`Getting Started`}</h2>
    <p>{`To get started with the NestJS boilerplate,
you will need to `}<a parentName="p" {...{
        "href": "https://github.com/sponsors/zsevic"
      }}>{`become a GitHub sponsor`}</a>{`.
Once you are a `}<a parentName="p" {...{
        "href": "https://github.com/sponsors/zsevic"
      }}>{`sponsor`}</a>{`,
you will automatically gain access to the `}<a parentName="p" {...{
        "href": "https://github.com/delimitertech/nestjs-starter"
      }}>{`repository`}</a>{`.`}</p>
    <p>{`From there, you can clone the `}<a parentName="p" {...{
        "href": "https://github.com/delimitertech/nestjs-starter"
      }}>{`repository`}</a>{` and start using the boilerplate
for your projects.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      